
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { RawLocation } from 'vue-router';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import Form from '@/components/mixins/formChecks';
import { RouteNames, TextfieldInfo } from '@/constants';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { openSnackbar } from '@/utils/components';
import GRadioGroupThree from '@/Rpa/components/gsk-components/GskRadioGroupThree.vue';
import { TokensModule } from '@/store/modules/tokens.module';
import { HiddenToken } from '@/types/tokens.types';
import dayjs from 'dayjs';
import format from 'date-fns/format';
import { enGB } from 'date-fns/locale';

@Component({
  components: {
    FullScreenForm,
    GButton,
    GTextfield,
    GRadioGroupThree,
  },
})
export default class EditTokenView extends mixins(Form) {
  public loading = true;
  public tokenId = '';
  public token: HiddenToken = {
    patId: '',
    description: '',
    expireDate: '',
  };
  private expire = '6';
  public tokenDescription = '';
  public descriptionLabel: string = TextfieldInfo.descriptionLabel;
  public tokenDescriptionPlaceholder: string = TextfieldInfo.tokenDescriptionPlaceholder;
  public tokenDescriptionValidationMessage: string =
    TextfieldInfo.tokenDescriptionValidationMessage;

  back() {
    this.$router.safeBack(this.closeRoute);
  }

  backToTokens() {
    this.$router.push({ name: RouteNames.MyTokens });
  }

  get checkExpire(): string {
    return this.expire;
  }

  set checkExpire(checked: string) {
    this.expire = checked;
  }

  get expireDate() {
    return format(new Date(this.token.expireDate), 'd MMMM, yyyy h:mm aa', { locale: enGB });
  }

  get expirePeriods() {
    return [
      {
        label: 'One Month',
        value: '1',
        checked: false,
      },
      {
        label: 'Three Months',
        value: '3',
        checked: false,
      },
      {
        label: 'Six Months',
        value: '6',
        checked: true,
      },
    ];
  }

  async saveToken(): Promise<void> {
    try {
      await TokensModule.updateToken(this.updateToken);
      await this.$router.push({ name: RouteNames.MyTokens });
      openSnackbar.call(this, 'Token Updated');
    } catch (err: any) {
      openSnackbar.call(this, err.message, { type: 'error' });
    }
  }

  get updateToken(): HiddenToken {
    return {
      patId: this.tokenId,
      description: this.tokenDescription,
      expireDate: dayjs().add(Number(this.expire), 'month').format(),
    };
  }

  get canSave(): boolean {
    return !!this.tokenDescription && !!this.expire;
  }

  get closeRoute(): RawLocation {
    return {
      name: RouteNames.MyTokens,
    };
  }

  created() {
    this.tokenId = this.$route.params.tokenId;
    TokensModule.tokenById(this.tokenId).then(data => {
      this.token = data;
      this.tokenDescription = data.description || '';
      this.expire = '6';
      this.loading = false;
    });
  }

  get disabled(): boolean {
    return this.loading;
  }

  get submitDisabled(): boolean {
    return !this.tokenDescription || !this.expire;
  }
}
